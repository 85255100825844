import React, { useRef } from "react"
import { Helmet } from "react-helmet"

import About from "./components/About"
import Hero from "./components/Hero"
import Intro from "./components/Intro"
import Offer from "./components/Offer"
import Page from "./components/Page"
import Quality from "./components/Quality"
import Steps from "./components/Steps"
import Workouts from "./components/Workouts"

const NewLandingPage = () => {
  const scrollToRef = useRef(null)

  return (
    <Page>
      <Helmet>
        <title>
          Anonimatta - Você nunca viu tanto conteúdo no mesmo lugar!
        </title>
      </Helmet>
      <Hero scrollToRef={scrollToRef} />
      <Steps scrollToRef={scrollToRef} />
      {/* <Intro /> */}
      {/* <Workouts /> */}
      {/* <Quality /> */}
      {/* <About /> */}
      <Offer className="section-border-top" />
    </Page>
  )
}

export default NewLandingPage
