import ModelPhotographed from "@components/ModelPhotographedAnonimatta"
import PostFeed from "@components/PostFeed"
import EssayGallery from "@modules/Favorites/EssayGallery"
import translate from "@src/i18n"
import { Col, Row, Tabs } from "antd"
import cx from "classnames"
import _map from "lodash/map"
import React from "react"
import Loading from "@components/Loading"

import styles from "./style.module.less"

const { TabPane } = Tabs

const Feed = ({
  posts,
  photosEssay,
  videosEssay,
  profilesFollowing,
  loadingPosts,
  loadingEssayMedia,
  loadingProfilesFollowing,
  handleFollowers,
  handleUnfollowers,
  handleModelProfile,
  handleLikeUnlike,
}) => {
  const TabName = (props) => {
    const { name, quantity, className } = props

    return (
      <div className={cx("flex flex-row items-baseline", className)}>
        <span>{name}</span>
      </div>
    )
  }

  return (
    <Tabs className={cx("favorites__tabs", styles.tabsMargin)}>
      <TabPane
        tab={<TabName name="Posts" quantity={posts && posts.length} />}
        key="1"
      >
        <div className={styles.favorites__tabs__posts}>
          {!loadingPosts && (
            <PostFeed
              className={cx(
                "favorites__tabs__posts",
                styles.marginContainerFeedProfile
              )}
              data={posts}
              recommendedProfiles={false}
              handleLikeUnlike={handleLikeUnlike}
              isFeedEmpty={true}
            />
          )}

          {loadingPosts ? <Loading /> : null}
        </div>
      </TabPane>
      <TabPane
        tab={
          <TabName
            className={styles.favorites__tabs__photos}
            name={translate("photos")}
            quantity={photosEssay && photosEssay.length}
          />
        }
        key="2"
      >
        <>
          {!loadingProfilesFollowing && (
            <EssayGallery
              className={cx(
                "favorites__tabs__photos w-100",
                styles.marginContainerFeedProfile
              )}
              data={photosEssay}
              handleLikeUnlike={handleLikeUnlike}
            />
          )}
          {loadingEssayMedia ? <Loading /> : null}
        </>
      </TabPane>
      <TabPane
        tab={
          <TabName
            className={styles.favorites__tabs__videos}
            name={translate("videos")}
            quantity={videosEssay && videosEssay.length}
          />
        }
        key="3"
      >
        <>
          {!loadingProfilesFollowing && (
            <EssayGallery
              className="favorites__tabs__videos"
              data={videosEssay}
              isVideo
              handleLikeUnlike={handleLikeUnlike}
            />
          )}
          {loadingEssayMedia ? <Loading /> : null}
        </>
      </TabPane>
      <TabPane
        tab={
          <TabName
            name={translate("profiles")}
            quantity={profilesFollowing && profilesFollowing.length}
          />
        }
        key="4"
      >
        <div className={styles.favorites__tabs__profiles}>
          {!loadingProfilesFollowing && (
            <Row
              gutter={[12, 12]}
              className={cx("", styles.marginContainerFeedProfile)}
            >
              {_map(profilesFollowing, (item) => (
                <Col
                  key={item.id}
                  span={8}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={6}
                  className={cx(" center", styles.feedProfileItem)}
                >
                  <ModelPhotographed
                    className="favorites__tabs__profiles"
                    model={item}
                    handleFollowers={handleFollowers}
                    handleUnfollowers={handleUnfollowers}
                    handleModelProfile={handleModelProfile}
                    handleLikeUnlike={handleLikeUnlike}
                    isFavorite
                    type={"favorites"}
                  />
                </Col>
              ))}
            </Row>
          )}

          {loadingProfilesFollowing ? <Loading /> : null}
        </div>
      </TabPane>
    </Tabs>
  )
}

export default Feed
