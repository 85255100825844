import { CloseOutlined } from "@ant-design/icons"
import { Icon } from "@components/Icons"
import TranslateWrapper from "@components/TranslateWrapper"
import { Divider } from "antd"
import cx from "classnames"
import React from "react"
import { useIntl } from "react-intl"
import { useMedia } from "react-use-media"
import { useStores } from "@hooks/use-stores"
import SvgCloseModal from "../../../../../components/Icons/components/CloseModal"
import styles from "./style.module.less"

export const Header = ({ handleClose }) => {
  const desktop = useMedia({ minWidth: 1024 })

  const intl = useIntl()

  const { authSite } = useStores()

  const { user } = authSite

  const urlProfile = `${process.env.S3_IMAGES}/${user.urlProfile}`

  if (desktop) {
    return (
      <TranslateWrapper>
        <div className={styles.header__container}>
          <div className={styles.header__title__container}>
            <img
              src={urlProfile}
              alt={intl.formatMessage({ id: "avatarUserProfileAlt" })}
              className={styles.iconSize}
            />
            <p className={styles.header__title}>
              {intl.formatMessage({ id: "newPost.title" })}
            </p>
          </div>
          <button
            title="Close (Esc)"
            type="button"
            className={styles.buttonClose}
            onClick={handleClose}
          >
            <SvgCloseModal />
          </button>

          {/*  <div role="button" onClick={handleClose} className="pointer">
            <CloseOutlined className="absolute top-0 right-0 mt24 mr24 f16" />
          </div> */}
        </div>
      </TranslateWrapper>
    )
  }

  return (
    <TranslateWrapper>
      <div className={styles.header__container}>
        <div className={styles.header__title__container}>
          <img
            src={urlProfile}
            alt={intl.formatMessage({ id: "avatarUserProfileAlt" })}
            className={styles.iconSize}
          />
          <p className={styles.header__title}>
            {intl.formatMessage({ id: "newPost.title" })}
          </p>
        </div>
        <button
          title="Close (Esc)"
          type="button"
          onClick={handleClose}
          className={styles.buttonClose}
        >
          <SvgCloseModal />
        </button>
        {/* <div role="button" onClick={handleClose} className="pointer">
            <CloseOutlined className="absolute top-0 right-0 mt24 mr24 f16" />
          </div> */}
      </div>
      {/*  <div className={cx("flex", styles.marginTitle)}>
        <div role="button" onClick={handleClose}>
          <Icon name="BackArrow" className={styles.iconBackPostage} />
        </div>

        <span className="f18 fw6 mine-shaft">
          {intl.formatMessage({ id: "newPost.title" })}
        </span>
      </div>

      <Divider className="divider-title" /> */}
    </TranslateWrapper>
  )
}
