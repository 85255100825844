import MyAccountModal from "@components/myAccountModal"
import ManagePublicEmail from "@modules/MyAccount/ManagePublicEmail"
import React, { useEffect, useState } from "react"

const ChangePublicEmailPage = () => {
  const [visible, setVisible] = useState(true)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleClose = () => {
    setVisible(false)
  }

  if (!isClient) return null

  return (
    <MyAccountModal visible={visible} handleClose={handleClose}>
      <ManagePublicEmail />
    </MyAccountModal>
  )
}

export default ChangePublicEmailPage
