import { useStores } from "@hooks/use-stores"
import translate from "@src/i18n"
import { Avatar, Badge, Button, Menu } from "antd"
import Col from "antd/es/grid/col"
import cx from "classnames"
import { Link } from "gatsby"
import { Observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import * as Icon from "react-feather"
import { useIntl } from "react-intl"
import { io } from "socket.io-client"

import { getTimeHumanized } from "../../../../utils/date"
import { getWindow, isBrowser } from "../../../../utils/getWindow"
import styles from "./style.module.less"

export const LOCAL_NOTIFICATIONS = "localNewNotifications"

const NotificationsMobile = () => {
  const intl = useIntl()

  const [socket, setSocket] = useState(null)

  let localNewNotifications = 0
  if (isBrowser()) {
    localNewNotifications =
      JSON.parse(getWindow().localStorage.getItem(LOCAL_NOTIFICATIONS)) || 0
  }

  const [newNotificationsCounter, setNewNotificationsCounter] = useState(
    localNewNotifications
  )

  const [lastNotifications, setLastNotifications] = useState([])

  const { authSite, notifications } = useStores()

  const { user } = authSite

  const { listLastUserNotifications } = notifications

  async function fetchLastNotifications() {
    if (user) {
      const not = await listLastUserNotifications(user.id)
      setLastNotifications(not)
    }
  }

  useEffect(() => {
    fetchLastNotifications()
  }, [])

  useEffect(() => {
    async function fetchLastNotifications() {
      const newSocket = await io.connect(process.env.API_URL, { secure: true })

      await newSocket.on(`msgToClient-${user.id}`, (msg) => {
        setNewNotificationsCounter(newNotificationsCounter + 1)
        getWindow().localStorage.setItem(
          LOCAL_NOTIFICATIONS,
          JSON.stringify(newNotificationsCounter + 1)
        )
        fetchLastNotifications()
      })
      await setSocket(newSocket)
      return () => {
        newSocket.close()
      }
    }
    fetchLastNotifications()
  }, [])

  var activeFiltersMap = []
  activeFiltersMap["ALL"] = translate("all")
  activeFiltersMap["NEW_POST"] = translate("notification.NEW_POST")
  activeFiltersMap["PAYMENTNEW_LIKE"] = translate(
    "notification.PAYMENTNEW_LIKE"
  )
  activeFiltersMap["NEW_COMMENT"] = translate("notification.NEW_COMMENT")
  activeFiltersMap["COMMENT_REPLY"] = translate("notification.COMMENT_REPLY")
  activeFiltersMap["NEW_ESSAY"] = translate("notification.NEW_ESSAY")
  activeFiltersMap["NEW_FOLLOWER"] = translate("notification.NEW_FOLLOWER")
  activeFiltersMap["PAYMENT"] = translate("notification.PAYMENT")

  const renderNotificationItem = (notification) => {
    const link = notification.internalLink || ""
    return (
      <li className={cx(" mb24  menu-item")}>
        <a
          href={link}
          className={cx(styles.notificatios)}
          key={`notification:${notification.id}`}
        >
          <div className="flex flex-row w-100">
            <Avatar
              src={`${process.env.S3_IMAGES}/${notification.userAvatar}`}
              className={cx("contain pointer", styles.notifications__avatar)}
            />
            <div className="flex flex-column ml16 w-100 justify-center">
              <div className="flex flex-row justify-between">
                <label
                  htmlFor=""
                  className={cx("", styles.notifications__author)}
                >
                  {notification?.userName}
                </label>
                <label
                  className={cx("lh-copy ml16 f12", styles.notifications__date)}
                >
                  {getTimeHumanized(notification.createdDate)}
                </label>
              </div>
              <p className={cx("", styles.notifications__message)}>
                {notification.message}
                <span className={styles.notifications__message__action}>
                  {notification.title}
                </span>
              </p>
            </div>
          </div>
        </a>
      </li>
    )
  }
  const renderNofitications = (lastNotifications) => {
    const itens = lastNotifications.map((item) => renderNotificationItem(item))

    return (
      <div>
        <label className={cx("ml16", styles.notifications__title)}>
          {translate("notificationTitleMenu")}
        </label>
        <div className={cx("pt24 pb24 pl16 pr16", styles.notitications__list)}>
          {itens}
        </div>

        <Col span={24} className="w-100 tc see__all__notifications">
          <Link to="/notificacoes">
            <Button type="primary" className="see__all__notifications__button">
              {translate("notificationsLink")}
            </Button>
          </Link>
        </Col>
      </div>
    )
  }

  return (
    <Menu
      style={{ border: "none" }}
      className=" custom-anonimatta-menu"
      mode="horizontal"
      triggerSubMenuAction="click"
    >
      <Menu.Item
        style={{ padding: 0 }}
        key="notificationsSub"
        icon={
          <Observer>
            {() => (
              <Badge count={newNotificationsCounter}>
                <Link to="/notificacoes">
                  <Icon.Bell color="#777" size="20" />
                </Link>
              </Badge>
            )}
          </Observer>
        }
        className="anonimatta-submenu notification-submenu"
        // onTitleClick={() => {}}
      >
        {/* <div style={{ width: "100%" }}>
          {lastNotifications.length ? (
            <Col span={24} className={styles.notifications__container}>
              {renderNofitications(lastNotifications)}
            </Col>
          ) : (
            <span className="f14 fw5 lh-copy">
              Nenhuma notificação a ser exibida
            </span>
          )}
        </div> */}
      </Menu.Item>
    </Menu>
  )
}
export default NotificationsMobile
