import { DownOutlined } from "@ant-design/icons"
import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import { Menu } from "antd"
import cx from "classnames"
import { Link } from "gatsby"
import _map from "lodash/map"
import React from "react"

import { Languages } from "../../"
import styles from "../../style.module.less"
import { LanguageMenuItem } from "./components/LanguageMenuItem"

export const UnloggedAreaMenu = ({ language, setLanguage }) => {
  const menuItems = [
    {
      key: "menu-item-portuguese",
      iconName: "IconBrazil",
      label: translate("portuguese"),
      active: language === Languages.PORTUGUESE,
      onClick: () => setLanguage(Languages.PORTUGUESE),
    },
    {
      key: "menu-item-spanish",
      iconName: "IconSpain",
      label: translate("spanish"),
      active: language === Languages.SPANISH,
      onClick: () => setLanguage(Languages.SPANISH),
    },
    {
      key: "menu-item-english",
      iconName: "IconUnitedKingdom",
      label: translate("english"),
      active: language === Languages.ENGLISH,
      onClick: () => setLanguage(Languages.ENGLISH),
    },
  ]

  return (
    <Menu className="flex flex-wrap anonimatta-menu" mode="horizontal">
      <Menu.SubMenu
        key="subMenuLanguage"
        title={
          <span
            className={cx(
              "flex items-center justify-between",
              styles.bottomSpan
            )}
          >
            {translate("language")}
            <DownOutlined className={styles.downIcon} />
          </span>
        }
      >
        {_map(menuItems, ({ key, iconName, label, active, onClick }) => (
          <Menu.Item key={key} onClick={onClick} active={active}>
            <LanguageMenuItem
              iconName={iconName}
              label={label}
              active={active}
              onClick={onClick}
            />
          </Menu.Item>
        ))}
      </Menu.SubMenu>

      <Menu.Item>
        <Link to="/login">
          <div className="flex flex-row">
            <div className="flex">
              <div className="flex items-center">
                <Icon name="UserOutline" className={styles.iconUserOutline} />
                <span className="ml8">{translate("enterNotUser")}</span>
              </div>
            </div>
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  )
}
