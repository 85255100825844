import CustomModalPost from "@components/CustomModalPost"
import React from "react"
import { useMedia } from "react-use-media"

const PostageModal = ({ children, ...props }) => {
  const desktop = useMedia({ minWidth: 1024 })

  return (
    <CustomModalPost
      className="modal__new__post"
      centered={desktop}
      closable={false}
      {...props}
    >
      {children}
    </CustomModalPost>
  )
}

export default PostageModal
