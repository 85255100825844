import translate from "@src/i18n"
import React from "react"

import styles from "./style.module.less"

const Banner = ({ total }) => {
  return (
    <div className={styles.favorites__header}>
      <div className={styles.favorites__header__title}>
        <h1>{total} favoritos</h1>
      </div>
      {/* <p className={styles.favorites__header__description}>
        Publicações, fotos, vídeos e perfis.
      </p> */}
      {/* <div
        className={styles.favorites__header__img}
        style={{ backgroundImage: `url(${require("@images/rosto.jpg")}) ` }}
      ></div> */}
    </div>
  )
}

export default Banner
