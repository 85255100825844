import { Icon } from "@components/Icons"
import { Avatar } from "antd"
import cx from "classnames"
import { Link } from "gatsby"
import React from "react"
import { useIntl } from "react-intl"

import TranslateWrapper from "../../../TranslateWrapper"
import styles from "../../style.module.less"

export const MenuItem = ({
  active,
  labelText,
  linkTo,
  urlProfile,
  iconMobile,
  iconMobileActive,
}) => {
  const intl = useIntl()

  return (
    <TranslateWrapper>
      <Link to={linkTo} className="flex flex-column items-center">
        {urlProfile ? (
          <Avatar
            src={urlProfile}
            alt={intl.formatMessage({ id: "avatarUserProfileAlt" })}
            className={styles.iconFooter}
          />
        ) : (
          <div className={styles.sizeGeneral}>
            {active ? iconMobileActive : iconMobile}
          </div>
        )}

        <div className="text-menu-mobile">
          <label
            className={cx("label-menu-mobile", {
              "french-rose": active,
              "default-color": !active,
            })}
          >
            {labelText}
          </label>
        </div>
      </Link>
    </TranslateWrapper>
  )
}
