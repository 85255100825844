import cx from "classnames"
import React from "react"

import electricalPlugImage from "../../../../images/content/electrical-plug.svg"
import facebookImage from "../../../../images/content/facebook.svg"
import flagImage from "../../../../images/content/flag.svg"
import userGreenImage from "../../../../images/content/user-green.svg"
import ScrollParallax from "../ScrollParallax"
import styles from "./Quality.module.less"

const items = [
  {
    title: "Modelos",
    counter: "+150",
    images: userGreenImage,
    alt: "user",
    content:
      "Mais de 150 modelos já fazem parte do nosso catálogo com atualizações semanais.",
  },
  {
    title: "Mídias",
    counter: "+6k",
    images: electricalPlugImage,
    alt: "electrical-plus",
    content:
      "Nossa comunidade possui atualmente mais de 6.000 mídias, entre fotos e vídeos.",
  },
  {
    title: "Países",
    counter: "+15",
    images: flagImage,
    alt: "flag",
    content:
      "O Anonimatta orgulhosamente possui assinantes em mais de 15 países diferentes.",
  },
  {
    title: "Instagram",
    counter: "+110k",
    images: facebookImage,
    alt: "facebook",
    content:
      "Siga o Anonimatta e fique por dentro de todas novidades, bastidores e recrutamentos.",
  },
]

const Quality = () => {
  return (
    <div className={cx("section-pb", styles.section)}>
      <div className={cx("container", styles.container)}>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div className={styles.preview}>
                <img src={x.images} alt={x.alt} />
              </div>
              <div className={styles.counter}>{x.counter}</div>
              <div className={styles.title}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Quality
