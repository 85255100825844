import React from "react"

import styles from "./style.module.less"

const PageWrapper = ({ children, closeSidebar }) => {
  return (
    <div
      className={
        closeSidebar
          ? styles.page__wrapper__sidebarClosed
          : styles.page__wrapper
      }
    >
      {children}
    </div>
  )
}
export default PageWrapper
