import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { useMedia } from "react-use-media"
import cx from "classnames"

import { CustomModalErrors } from "@components/CustomModalErrors"
import CustomFilterbar from "@components/CustomSearchAnonimatta"
import { SuccessModalStylized } from "@components/SuccessModalStylized"
import { useStores } from "@hooks/use-stores"
import { NewPost } from "@modules/Post/NewPost"
import { useNotificationsCounter } from "../../hooks/use-notifications-counter"
import { MenuDesktop } from "./components/MenuDesktop"
import { MenuMobile } from "./components/MenuMobile"
import NotificationsMobile from "./components/NotificationsMobile/"

export const MenuItemLinkPaths = {
  ESSAY: "/ensaios",
  FEED: "/feed",
  POST: "/post",
  SAVED: "/salvos",
  FAVORITES: "/favoritos",
  ACCOUNT: "/minha-conta",
}

export const Languages = {
  PORTUGUESE: 1,
  SPANISH: 2,
  ENGLISH: 3,
}

const AnonimattaMenu = (props) => {
  const [language, setLanguage] = useState(Languages.PORTUGUESE)
  const [visibleNewPostModal, setVisibleNewPostModal] = useState(false)
  const [errors, setErrors] = useState([])
  const [successMessage, setSuccessMessage] = useState("")

  const [incrementCounter, getCounter] = useNotificationsCounter()

  const intl = useIntl()

  const desktop = useMedia({ minWidth: 1024 })

  const { authSite } = useStores()

  const { user } = authSite

  const { location, setRefreshFeed } = props

  const currentPathName = location.pathname

  const handleCloseNewPostModal = () => {
    setVisibleNewPostModal(false)
  }

  const handleCloseSuccessModal = () => {
    handleCloseNewPostModal()
    setSuccessMessage("")
    setRefreshFeed(true)
  }

  const handleSumNotifications = () => {
    incrementCounter()
  }
  const headerClass = props.closeSidebar ? "header__sidebar__close" : "header"

  return (
    <>
      <div
        className={cx(headerClass, {
          "justify-between": !user || desktop,
          "justify-center": user || !desktop,
        })}
      >
        <div className="menu-mobile-anonimatta">
          <CustomFilterbar handleSearch={() => navigate("/criadores")} />

          <Link to="/feed">
            <img
              className="logo-mobile-anonimatta"
              src={require("@images/img/logo.svg")}
              alt="Anonimatta"
            />
          </Link>

          <NotificationsMobile />
        </div>

        {desktop ? (
          <MenuDesktop
            currentPathName={currentPathName}
            language={language}
            setLanguage={setLanguage}
            setVisibleNewPostModal={setVisibleNewPostModal}
            notificationsCounter={getCounter()}
            handleSumNotifications={handleSumNotifications}
            {...props}
          />
        ) : (
          <MenuMobile
            currentPathName={currentPathName}
            language={language}
            setLanguage={setLanguage}
            notificationsCounter={getCounter()}
            setVisibleNewPostModal={setVisibleNewPostModal}
            handleSumNotifications={handleSumNotifications}
            {...props}
          />
        )}

        {visibleNewPostModal && user ? (
          <NewPost
            visible={visibleNewPostModal}
            handleClose={handleCloseNewPostModal}
            setErrors={setErrors}
            setSuccessMessage={setSuccessMessage}
          />
        ) : null}

        {errors.length ? (
          <CustomModalErrors errors={errors} setErrors={setErrors} />
        ) : null}

        {successMessage ? (
          <SuccessModalStylized
            visible={!!successMessage}
            description={successMessage}
            handleClose={handleCloseSuccessModal}
            modalOption="sharedPost"
            buttonText={intl.formatMessage({ id: "continue" })}
          />
        ) : null}
      </div>
    </>
  )
}

export default AnonimattaMenu
