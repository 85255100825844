import React from "react"

import styles from "../style.module.less"

const EmptyMediaFavorite = () => {
  return (
    <div className={styles.empty__media}>
      <div className={styles.errorWidth}>
        <div className={styles.empty__media__container}>
          <img
            className={styles.empty__media__img}
            alt="Nenhum favorito"
            src={require("@images/icons/emptyFavorites.svg")}
          />
        </div>
      </div>
    </div>
  )
}

export default EmptyMediaFavorite
