import { useStores } from "@hooks/use-stores"
import { normalizePath } from "@utils/path"
import cx from "classnames"
import React, { useEffect, useState } from "react"
import * as Icon from "react-feather"
import { io } from "socket.io-client"

import { MenuItemLinkPaths } from "../.."
import styles from "../../style.module.less"
import { NewPost } from "../NewPost"
import { UnloggedAreaMenu } from "../UnloggedAreaMenu"
import { MenuItem } from "./MenuItem"

export const MenuMobile = ({
  currentPathName,
  language,
  setLanguage,
  setVisibleNewPostModal,
  handleSumNotifications,
}) => {
  const [, setSocket] = useState(null)

  const { authSite } = useStores()

  const { user } = authSite

  useEffect(() => {
    ;(async () => {
      const newSocket = await io.connect(process.env.API_URL, { secure: true })
      await newSocket.on(`msgToClient-${user.id}`, (msg) => {
        console.log("checgou mensagem ", msg)
        handleSumNotifications(msg)
      })
      await setSocket(newSocket)
      return () => {
        newSocket.close()
      }
    })()
  }, [])

  if (user) {
    const creators = !!user.type
    const urlProfile = `${process.env.S3_IMAGES}/${
      user.urlProfile || user.urlPhoto
    }`

    const pageUri = normalizePath(`/perfil/${user.username}`)

    return (
      <div className={cx("flex flex-row justify-around", styles.menuFooter)}>
        <MenuItem
          linkTo="/ensaios"
          labelText="Ensaios"
          active={currentPathName.includes(MenuItemLinkPaths.ESSAY)}
          iconMobile={<Icon.Camera size="18" color="#808191" />}
          iconMobileActive={<Icon.Camera size="18" />}
        />

        <MenuItem
          linkTo={"/favoritos"}
          labelText="Favoritos"
          active={currentPathName.includes(MenuItemLinkPaths.FAVORITES)}
          iconMobile={<Icon.Bookmark size="18" color="#808191" />}
          iconMobileActive={<Icon.Bookmark size="18" />}
        />

        {creators && (
          <NewPost
            currentPathName={currentPathName}
            setVisibleNewPostModal={setVisibleNewPostModal}
          />
        )}

        <MenuItem
          linkTo="/feed"
          labelText="Feed"
          active={currentPathName.includes(MenuItemLinkPaths.FEED)}
          iconMobile={<Icon.Layout size="18" color="#808191" />}
          iconMobileActive={<Icon.Layout size="18" />}
        />

        {creators ? (
          <MenuItem
            linkTo={pageUri}
            labelText="Perfil"
            className="menuMobileAccount"
            active={
              currentPathName.includes("conta-assinante") ||
              currentPathName.includes("conta-criador")
            }
            urlProfile={urlProfile}
          />
        ) : (
          <MenuItem
            linkTo="/minha-conta"
            labelText="Conta"
            className="menuMobileAccount"
            active={
              currentPathName.includes("conta-assinante") ||
              currentPathName.includes("conta-criador")
            }
            urlProfile={urlProfile}
          />
        )}
      </div>
    )
  }

  return <UnloggedAreaMenu language={language} setLanguage={setLanguage} />
}
