import "./style.module.less"

import React, { useState } from "react"

import { Content } from "./components/Content"
import { Header } from "./components/Header"
import PostageModal from "./components/PostageModal"

export const NewPost = ({
  handleClose,
  visible,
  setErrors,
  setSuccessMessage,
}) => {
  const [fileReader, setFileReader] = useState("")
  const [loading, setLoading] = useState(false)

  return (
    <PostageModal visible={visible} handleClose={handleClose}>
      <div className="descriptionPostage">
        <Header handleClose={handleClose} />

        <Content
          setFileReader={setFileReader}
          fileReader={fileReader}
          loading={loading}
          setLoading={setLoading}
          setErrors={setErrors}
          setSuccessMessage={setSuccessMessage}
        />
      </div>
    </PostageModal>
  )
}
