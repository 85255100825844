import CustomFilterbar from "@components/CustomSearchAnonimatta"
import { useStores } from "@hooks/use-stores"
import translate from "@src/i18n"
import { normalizePath } from "@utils/path"
import { Avatar, Badge, Button, Menu } from "antd"
import Col from "antd/es/grid/col"
import cx from "classnames"
import { Link, navigate } from "gatsby"
import { Observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import * as Icon from "react-feather"
import { useIntl } from "react-intl"
import { io } from "socket.io-client"

import { getTimeHumanized } from "../../../../utils/date"
import TranslateWrapper from "../../../TranslateWrapper"
import styles from "../../style.module.less"
import { NewPost } from "../NewPost"
import { UnloggedAreaMenu } from "../UnloggedAreaMenu"

export const MenuDesktop = ({
  currentPathName,
  language,
  setLanguage,
  setVisibleNewPostModal,
  notificationsCounter,
  handleSumNotifications,
}) => {
  const intl = useIntl()
  const [, setSocket] = useState(null)

  const [lastNotifications, setLastNotifications] = useState([])

  const { authSite, notifications } = useStores()

  const { user, logout } = authSite

  const { listLastUserNotifications } = notifications

  async function fetchLastNotifications() {
    if (user) {
      const notifications = await listLastUserNotifications(user.id)
      setLastNotifications(notifications)
    }
  }
  useEffect(() => {
    fetchLastNotifications()
  }, [])

  useEffect(() => {
    async function fetchLastNotifications() {
      const newSocket = await io.connect(process.env.API_URL, { secure: true })
      await newSocket.on(`msgToClient-${user.id}`, (msg) => {
        handleSumNotifications(msg)
        fetchLastNotifications()
      })
      await setSocket(newSocket)
      return () => {
        newSocket.close()
      }
    }
    fetchLastNotifications()
  }, [])
  var activeFiltersMap = []
  activeFiltersMap["ALL"] = translate("all")
  activeFiltersMap["NEW_POST"] = translate("notification.NEW_POST")
  activeFiltersMap["PAYMENTNEW_LIKE"] = translate(
    "notification.PAYMENTNEW_LIKE"
  )
  activeFiltersMap["NEW_COMMENT"] = translate("notification.NEW_COMMENT")
  activeFiltersMap["COMMENT_REPLY"] = translate("notification.COMMENT_REPLY")
  activeFiltersMap["NEW_ESSAY"] = translate("notification.NEW_ESSAY")
  activeFiltersMap["NEW_FOLLOWER"] = translate("notification.NEW_FOLLOWER")
  activeFiltersMap["PAYMENT"] = translate("notification.PAYMENT")

  const renderNotificationItem = (notification) => {
    const link = notification.internalLink || ""
    return (
      <li className={cx(" mb16  menu-item")}>
        <a
          href={link}
          className={cx(styles.notificatios)}
          key={`notification:${notification.id}`}
        >
          <div className="flex flex-row w-100">
            <Avatar
              src={`${process.env.S3_IMAGES}/${notification.userAvatar}`}
              className={cx("contain pointer", styles.notifications__avatar)}
              // onClick={(event) => handleModelProfile(event, model.id)}
            />
            <div className="flex flex-column ml16 w-100 justify-center">
              <div className="flex flex-row justify-between">
                <label
                  htmlFor=""
                  className={cx("", styles.notifications__author)}
                >
                  {notification?.userName}
                </label>
                <label
                  className={cx("lh-copy ml16 f12", styles.notifications__date)}
                >
                  {getTimeHumanized(notification.createdDate)}
                </label>
              </div>
              <p className={cx("", styles.notifications__message)}>
                {/*  <p
                  className={cx("", styles.notifications__message__text)}
                  htmlFor=""
                > */}
                {notification.message} {/* </p> */}
                <span className={styles.notifications__message__action}>
                  {notification.title}
                </span>
              </p>
            </div>
          </div>
        </a>
      </li>
    )
  }
  const renderNofitications = (lastNotifications) => {
    const itens = lastNotifications.map((item) => renderNotificationItem(item))

    return (
      <div>
        <label className={cx("ml16", styles.notifications__title)}>
          {translate("notificationTitleMenu")}
        </label>
        <div className={cx("pt24 pb8 pl16 pr16", styles.notitications__list)}>
          {itens}
        </div>

        <Col span={24} className="w-100 tc see__all__notifications">
          <Link to="/notificacoes">
            <Button type="primary" className="see__all__notifications__button">
              {translate("notificationsLink")}
            </Button>
          </Link>
        </Col>
      </div>
    )
  }

  if (user) {
    const creators = !!user.type

    const handleClickLogout = () => logout()
    const pageUri = normalizePath(`/perfil/${user.username}`)

    const getMenuItemLinkStyles = (linkTo) => {
      const active = currentPathName.includes(linkTo)

      if (active) {
        return {
          color: "#EA4C89",
          textDecoration: "none",
        }
      }

      return {}
    }

    return (
      <TranslateWrapper>
        <CustomFilterbar handleSearch={() => navigate("/criadores")} />
        <Menu
          className="anonimatta-menu custom-anonimatta-menu"
          mode="horizontal"
          selectedKeys={[]}
        >
          <Menu.SubMenu
            key="notificationsSub"
            icon={
              <Observer>
                {() => (
                  <Badge count={notificationsCounter}>
                    <span style={{ marginTop: "3px" }}>
                      <Icon.Bell color="#777" size="20" />
                    </span>
                  </Badge>
                )}
              </Observer>
            }
            className="anonimatta-submenu notification-submenu"
            onTitleClick={() => {}}
          >
            <div style={{ width: "320px" }}>
              {lastNotifications.length ? (
                <Col span={24} className={styles.notifications__container}>
                  {renderNofitications(lastNotifications)}
                </Col>
              ) : (
                <span className="f14 fw5 lh-copy">
                  Nenhuma notificação a ser exibida
                </span>
              )}
            </div>
          </Menu.SubMenu>

          <Menu.SubMenu
            key="subMenu"
            icon={
              <Observer>
                {() => (
                  <Avatar
                    src={`${process.env.S3_IMAGES}/${
                      user.urlProfile || user.urlPhoto
                    }`}
                    alt={intl.formatMessage({ id: "avatarUserProfileAlt" })}
                    className={styles.iconSize}
                  />
                )}
              </Observer>
            }
            title={
              <Observer>
                {() => (
                  <span
                    className="f16 fw6"
                    style={getMenuItemLinkStyles(
                      creators ? "/conta-criador" : "/conta-assinante"
                    )}
                  ></span>
                )}
              </Observer>
            }
            className="anonimatta-submenu"
          >
            <Menu.Item key="setting:profile">
              <Link to={pageUri} style={getMenuItemLinkStyles("/favoritos")}>
                <div className="flex">
                  <div className="flex items-center mr8">
                    <Icon.User color="#777" size="20" />
                  </div>

                  <span className="f14 fw5 lh-copy">Meu perfil</span>
                </div>
              </Link>
            </Menu.Item>

            <Menu.Item key="setting:favorites">
              <Link
                to={"/favoritos"}
                style={getMenuItemLinkStyles("/favoritos")}
              >
                <div className="flex">
                  <div className="flex items-center mr8">
                    <Icon.Bookmark color="#777" size="20" />
                  </div>

                  <span className="f14 fw5 lh-copy">Meus favoritos</span>
                </div>
              </Link>
            </Menu.Item>

            <Menu.Item key="setting:account">
              <Link
                to="/minha-conta"
                className="flex justify-between items-center"
                style={getMenuItemLinkStyles(
                  creators ? "/conta-criador" : "/conta-assinante"
                )}
              >
                <div className="flex">
                  <div className="flex items-center mr8">
                    <Icon.Settings color="#777" size="20" />
                  </div>

                  <span className="f14 fw5 lh-copy">Configurações</span>
                </div>
              </Link>
            </Menu.Item>

            <Menu.Item key="setting:exit">
              <Link
                to="/"
                className="flex justify-between items-center mt4"
                onClick={handleClickLogout}
              >
                <div className="flex">
                  <div className="flex items-center mr8 br4">
                    <Icon.LogOut color="#777" size="20" />
                  </div>

                  <span className="f14 fw5 lh-copy">{translate("exit")}</span>
                </div>
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>

        {creators && (
          <NewPost desktop setVisibleNewPostModal={setVisibleNewPostModal} />
        )}
      </TranslateWrapper>
    )
  }

  return <UnloggedAreaMenu language={language} setLanguage={setLanguage} />
}
