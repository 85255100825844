import OneSignal from "react-onesignal"

let staticUser = null
let staticPlayerId

export function initOneSignal(user, updateSubscriberPlayerId, updatePlayerId) {
  if (user) {
    staticUser = user
  }

  OneSignal.init({
    appId: process.env.ONESIGNAL_KEY,
    allowLocalhostAsSecureOrigin: true,
    autoRegister: false,
  }).then(() => {
    OneSignal.getUserId((newPlayerId) => {
      if (newPlayerId) {
        staticPlayerId = newPlayerId
        if (
          user &&
          newPlayerId &&
          (!user.playerId || user.playerId !== newPlayerId)
        ) {
          if (user.type === "subscriber") {
            updateSubscriberPlayerId(user.id, newPlayerId)
          } else {
            updatePlayerId(user.id, newPlayerId)
          }
        }
      }
    })
  })
}
