import { Icon } from "@components/Icons"
import cx from "classnames"
import React from "react"

import styles from "../../../style.module.less"

export const LanguageMenuItem = ({ iconName, label, active }) => {
  return (
    <div className="flex flex-row">
      <div className="flex">
        <div className="flex items-center">
          <Icon name={iconName} className={styles.iconsLanguage} />

          <span
            className={cx("f10 ml8", {
              b: active,
              normal: !active,
            })}
          >
            {label}
          </span>
        </div>
      </div>
    </div>
  )
}
