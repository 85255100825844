import cx from "classnames"
import { Link } from "gatsby"
import React from "react"
import * as Icon from "react-feather"

import styles from "./style.module.less"

const AnonimattaSidebar = ({ handleClose }) => {
  const toogleSidebar = () => {
    const sidebar = document.querySelector([`.${styles.sidebar}`])
    sidebar.classList.toggle(styles.sidebar__close)
    handleClose()
  }

  return (
    <div className={cx(styles.sidebar)}>
      <div className={cx(styles.sidebar__top)}>
        <Link to="/feed">
          <img
            className={cx(styles.sidebar__logo)}
            src={require("@images/img/logo.svg")}
            alt=""
          />
        </Link>
        <button
          className={cx(styles.sidebar__burger)}
          onClick={toogleSidebar}
        ></button>
        {/* <button className={cx(styles.sidebar__close)}>
        </button> */}
      </div>

      <div className={cx(styles.sidebar__wrapper)}>
        <div className={cx(styles.sidebar__inner)}>
          <div className={cx(styles.sidebar__group)}>
            <div className={cx(styles.sidebar__caption)}>
              <span>Conteúdos </span>
            </div>
            <div className={cx(styles.sidebar__menu)}>
              <Link
                to={"/feed/" || "/feed"}
                activeClassName={cx(styles.activesidebar)}
              >
                <span className={cx(styles.sidebar__item)}>
                  <div className={cx(styles.sidebar__icon)}>
                    <Icon.Layout size="20" />
                  </div>
                  <div className={cx(styles.sidebar__text)}>Feed</div>
                </span>
              </Link>

              <Link
                to={"/ensaios/" || "/ensaios"}
                activeClassName={cx(styles.activesidebar)}
              >
                <span className={cx(styles.sidebar__item)}>
                  <div className={cx(styles.sidebar__icon)}>
                    <Icon.Camera size="20" />
                  </div>
                  <div className={cx(styles.sidebar__text)}>Ensaios</div>
                </span>
              </Link>

              <Link
                to={"/favoritos/" || "/favoritos"}
                activeClassName={cx(styles.activesidebar)}
              >
                <span className={cx(styles.sidebar__item)}>
                  <div className={cx(styles.sidebar__icon)}>
                    <Icon.Bookmark size="20" />
                  </div>
                  <div className={cx(styles.sidebar__text)}>Favoritos</div>
                </span>
              </Link>
            </div>
          </div>
          <div className={cx(styles.sidebar__group)}>
            <div className={cx(styles.sidebar__caption)}>
              <span>Minha conta </span>
            </div>
            <div className={cx(styles.sidebar__menu)}>
              <Link to="/minha-conta" activeClassName="active-sidebar">
                <span className={cx(styles.sidebar__item)}>
                  <div className={cx(styles.sidebar__icon)}>
                    <Icon.Sliders size="20" />
                  </div>
                  <div className={cx(styles.sidebar__text)}>Configurações</div>
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className={cx(styles.sidebar__banner)}>
          <div className={cx(styles.sidebar__bg)}></div>
          <button className={styles.sidebar__remove}>
            <Icon.Radio color="#ffffff" size="16" />
          </button>
          <div className={styles.sidebar__details}>
            <div className={`${styles.sidebar__info} ${styles.caption__sm}`}>
              Lançamento dos Croppeds Anonimatta.
            </div>
            <button className={styles.sidebar__btn}>Em breve</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnonimattaSidebar
