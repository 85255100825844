import AnonimattaSidebar from "@components/AnonimattaComponents/AnonimattaSidebar"
import AnonimattaMenu from "@components/anonimattaMenu"
import PageWrapper from "@components/PageWrapper"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import { includes } from "lodash"
import queryString from "query-string"
import { useEffect, useState } from "react"
import React from "react"
import { Helmet } from "react-helmet"

import styles from "./style.module.less"

const LayoutSite = (props) => {
  const { authSite } = useStores()

  const { user } = authSite

  const { children, location } = props

  const parsedQueryString = queryString.parse(location.search)
  const pathNames = [
    "/",
    "/planos",
    "/planos/",
    "/privacidade/",
    "/privacidade",
    "/termos/",
    "/termos",
    "/faq",
    "/faq/",
    "/cadastro-assinante/",
    "/cadastro-assinante",
    "/cadastro-criador/",
    "/cadastro-criador",
    "/perfil/",
    "/pagina-nao-encontrada/",
    "/pagina-nao-encontrada",
  ]
  const [closeSidebar, setCloseSidebar] = useState(false)

  const handleClose = () => {
    setCloseSidebar((prevState) => !prevState)
  }

  const showMenu = () => {
    if (includes(pathNames, location.pathname)) {
      return false
    }
    const loginPage = location.pathname.includes("login")
    const adminPage = location.pathname.includes("admin")
    const essayDetailsPage =
      location.pathname.includes("ensaios") &&
      parsedQueryString &&
      parsedQueryString.searchId

    if (!loginPage && !adminPage) {
      return true
    }

    return false
  }

  const getStylesLayout = () => {
    const userLogged = user
    const isAdmin = location.pathname.includes("admin")
    const essayDetailsPage =
      location.pathname.includes("ensaios") &&
      parsedQueryString &&
      parsedQueryString.searchId

    if (userLogged && !isAdmin && !essayDetailsPage) {
      return styles.layout
    }

    return ""
  }

  useEffect(() => {
    var videos = document.getElementsByTagName("video")

    function checkScroll() {
      var fraction = 0.8

      for (var i = 0; i < videos.length; i++) {
        var video = videos[i]

        var x = video.offsetLeft,
          y = video.offsetTop,
          w = video.offsetWidth,
          h = video.offsetHeight,
          r = x + w, //right
          b = y + h, //bottom
          visibleX,
          visibleY,
          visible

        visibleX = Math.max(
          0,
          Math.min(
            w,
            window.pageXOffset + window.innerWidth - x,
            r - window.pageXOffset
          )
        )
        visibleY = Math.max(
          0,
          Math.min(
            h,
            window.pageYOffset + window.innerHeight - y,
            b - window.pageYOffset
          )
        )

        visible = (visibleX * visibleY) / (w * h)

        if (visible > fraction) {
          // video.play()
        } else {
          video.pause()
        }
      }
    }

    window.addEventListener("scroll", checkScroll, false)
    window.addEventListener("resize", checkScroll, false)
  }, [])

  const stylesLayout = getStylesLayout()

  const font = !location.pathname.includes("admin") ? "poppins" : styles.segoeUi

  return (
    <TranslateWrapper>
      <div className={`${font} ${stylesLayout}`}>
        {user && showMenu() && (
          <>
            <AnonimattaMenu {...props} closeSidebar={closeSidebar} />
            <AnonimattaSidebar
              handleClose={handleClose}
              closeSidebar={closeSidebar}
            />
          </>
        )}
        {user && showMenu() ? (
          <PageWrapper closeSidebar={closeSidebar}>{children}</PageWrapper>
        ) : (
          children
        )}
      </div>
    </TranslateWrapper>
  )
}

export default LayoutSite
