import cx from "classnames"
import React from "react"

import downloadImage from "../../../../images/content/download.svg"
import medalImage from "../../../../images/content/medal.svg"
import stopwatchImage from "../../../../images/content/stopwatch.svg"
import whistleImage from "../../../../images/content/whistle.svg"
import ScrollParallax from "../ScrollParallax"
import styles from "./Steps.module.less"

import * as Icon from "react-feather"

const items = [
  {
    status: "Novo",
    title: "Comunidade",
    color: "#3772FF",
    images: <Icon.Heart size="22" color="white" />,
    content:
      "Curta, siga e comente fotos e vídeos das modelos que você mais admira.",
  },
  {
    status: "Novo",
    title: "Feed",
    color: "#9757D7",
    images: <Icon.Layout size="22" color="white" />,
    content: "Acompanhe diariamente as postagens das modelos em um único feed.",
  },
  {
    status: "Novo",
    title: "Favoritos",
    color: "#EF466F",
    images: <Icon.Bookmark size="22" color="white" />,
    content:
      "As imagens e vídeos que você mais curtiu agora ficam armazenadas na sua página de favoritos.",
  },
  {
    status: "Em breve",
    title: "Afiliados",
    color: "#45B26B",
    images: <Icon.DollarSign size="22" color="white" />,
    content:
      "Indique amigos e ganhe até 30% de comissão sobre qualquer indicação de vendas.",
  },
]

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cx("section", styles.section)} ref={scrollToRef}>
      <div className={cx("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cx("h2", styles.title)}>Novidades</h2>
          <div className={styles.info}>
            Acesse os ensaios mais ousados com as mulheres mais lindas do
            Instagram.
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                {x.images}
                {/* <img src={x.images} alt={`itemImage ${index}`} /> */}
              </div>
              <div className={styles.number}>{x.status}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Steps
