import Card from "@components/Gallery/components/Card"
import EmptyMediaFavorite from "@components/Gallery/components/EmptyMediaFavorite"
import TheaterSwitch from "@components/TheaterSwitch"
import translate from "@src/i18n"
import { Row } from "antd"
import cx from "classnames"
import _map from "lodash/map"
import React, { useEffect, useState } from "react"
import Mansory, { ResponsiveMasonry } from "react-responsive-masonry"
import { useMedia } from "react-use-media"

import styles from "./style.module.less"

const EssayGallery = ({ data, isVideo, handleLikeUnlike }) => {
  const [theater, setTheater] = useState(false)
  const [minHeightCol, setMinHeightCol] = useState(463)
  const [isBottom, setIsBottom] = useState(false)
  const [scrolling, setScrolling] = useState(false)

  const isDesktop = useMedia({ minWidth: 1025 })
  const isLapTop = useMedia({ maxWidth: 1440 })

  useEffect(() => {
    window.onscroll = () => {
      setScrolling(true)
    }
    setInterval(() => {
      if (!scrolling) {
        setScrolling(false)
        handleScroll()
      }
    }, 1000)
    if (isBottom && !scrolling) {
      setIsBottom(false)
    }
  }, [scrolling])

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop

    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight

    const innerHeight = window.innerHeight + 15

    if (scrollTop + innerHeight >= scrollHeight) {
      setIsBottom(true)
    }
  }

  const handleTheaterMode = () => {
    setTheater((prevState) => !prevState)
    console.log(theater)
  }

  const renderContent = () => {
    return (
      <div className="">
        <TheaterSwitch
          handleTheaterMode={() => handleTheaterMode()}
          theater={theater}
        />
        {isDesktop ? (
          <Row gutter={theater ? [24, 24] : isLapTop ? [24, 32] : [40, 32]}>
            {_map(data, (item) => {
              item.urlImage = isVideo ? item.urlVideo : item.urlPhoto

              return (
                <Card
                  key={item.id}
                  item={item}
                  theater={theater}
                  heightCol={minHeightCol}
                  setHeightCol={setMinHeightCol}
                  isBottom={isBottom}
                  isVideo={isVideo}
                  isFavorite
                  handleLikeUnlike={handleLikeUnlike}
                />
              )
            })}
          </Row>
        ) : (
          <ResponsiveMasonry
            columnsCountBreakPoints={
              theater
                ? { 320: 4, 474: 4, 750: 4, 1024: 4 }
                : { 320: 1, 474: 1, 750: 2, 1024: 2 }
            }
          >
            <Mansory
              gutter={theater ? 5 : 24}
              className="mansoryClassFavorites"
            >
              {_map(data, (item) => {
                item.urlImage = item.urlPhoto
                return (
                  <Card
                    key={item.id}
                    item={item}
                    theater={theater}
                    heightCol={minHeightCol}
                    setHeightCol={setMinHeightCol}
                    isBottom={isBottom}
                    isFavorite
                    handleLikeUnlike={handleLikeUnlike}
                  />
                )
              })}
            </Mansory>
          </ResponsiveMasonry>
        )}

        {data.length === 0 && (
          <EmptyMediaFavorite
            message={
              isVideo ? "emptyStateEssayVideos" : "emptyStateEssayPhotos"
            }
            isVideo={isVideo}
          />
        )}
      </div>
    )
  }

  return <>{renderContent()}</>
}

export default EssayGallery
