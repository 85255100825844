import cx from "classnames"
import React from "react"

import Icon from "../Icon"
import styles from "./ScrollButton.module.less"

const ScrollButton = ({ className, onScroll }) => {
  return (
    <button className={cx(styles.scroll, className)} onClick={onScroll}>
      <div className={styles.line}>
        <Icon name="arrow-down" size="14" />
        <Icon name="arrow-down" size="14" />
      </div>
    </button>
  )
}

export default ScrollButton
