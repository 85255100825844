import { LayoutAdmin } from "@components/LayoutAdmin"
import LayoutSite from "@components/layoutSite"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import { Redirect, Router } from "@reach/router"
import { navigate } from "@reach/router"
import _isEmpty from "lodash/isEmpty"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import NewSiteLandingPage from "../modules/NewLandingPage"
import SiteFAQ from "../pages/faq"
import SitePlanos from "../pages/planos"
import SitePrivacidade from "../pages/privacidade"
import SiteTermos from "../pages/termos"
import { initOneSignal } from "../utils/oneSignal"
import Admin from "./admin"
import AdminChangePassword from "./admin/alterar-senha"
import AdminSubscribers from "./admin/assinantes"
import AdminEssayBanners from "./admin/banners-ensaios"
import AdminEssayBannerForm from "./admin/banners-ensaios/cadastrar"
import AdminCreators from "./admin/criadores"
import AdminEssay from "./admin/ensaios"
import AdminEssayRegister from "./admin/ensaios/criar"
// import AdminPosts from "./admin/postagens"
// import AdminUsers from "./admin/usuarios/assinantes"
import PostAdminPage from "./admin/postagens"
import AdminUsers from "./admin/usuarios/assinantes"
import SiteBillet from "./boleto"
import SiteBilletExpired from "./boleto/boleto-expirado"
import SiteBilletNew from "./boleto/novo-boleto"
import SiteRegisterSubscriber from "./cadastro-assinante"
import SiteRegisterAffiliated from "./cadastro-criador"
import SiteSubscriberAccount from "./conta-assinante"
import SiteAccountModelPhotographer from "./conta-criador"
import SiteEssay from "./ensaios"
import SiteEssayDetails from "./ensaios/detalhes"
import SiteFavorite from "./favoritos"
import SiteFeed from "./feed"
import SiteLogin from "./login"
import SiteMyAccount from "./minha-conta"
import SiteMyAccountChangeEmail from "./minha-conta/alterar-email-publico"
import SiteMyAccountForgotPassword from "./minha-conta/esqueci-minha-senha"
import SiteMyAccountChangePassword from "./minha-conta/trocar-senha"
import NotificationsPage from "./notificacoes"
import SitePostProfile from "./p"
import SiteProfile from "./perfil"
import SiteProfileNotSubscriber from "./perfil/nao-assinante"
import PixPage from "./pix"
import CreatorsPage from "./criadores"

const NotFound = ({ isAdmin }) => {
  const { auth } = useStores()

  const intl = useIntl()

  const handleGoBack = () => {
    navigate(-1)
  }

  const redirectToNotFoundPage = !location.pathname.includes(
    "pagina-nao-encontrada"
  )

  if (redirectToNotFoundPage) {
    return (
      <Redirect
        to={isAdmin ? "/admin/pagina-nao-encontrada" : "/pagina-nao-encontrada"}
        noThrow
      />
    )
  }

  const { user } = auth

  if (isAdmin && user) {
    return (
      <LayoutAdmin>
        <TranslateWrapper>
          <div className="flex flex-column justify-center items-center vh-75 ph24">
            <h1 className="tc">
              {intl.formatMessage({ id: "pageNotFound.title" })}
            </h1>

            <p className="tc">
              {intl.formatMessage({ id: "pageNotFound.text" })}
            </p>

            <a href="/feed">
              {intl.formatMessage({ id: "pageNotFound.goBack" })}
            </a>
          </div>
        </TranslateWrapper>
      </LayoutAdmin>
    )
  }

  return (
    <TranslateWrapper>
      <div className="flex flex-column justify-center items-center vh-75 ph24">
        <h1 className="tc">
          {intl.formatMessage({ id: "pageNotFound.title" })}
        </h1>

        <p className="tc">{intl.formatMessage({ id: "pageNotFound.text" })}</p>

        <a href="/feed">{intl.formatMessage({ id: "pageNotFound.goBack" })}</a>
      </div>
    </TranslateWrapper>
  )
}

const PrivateRoute = ({
  component: Component,
  isAdminRoutes,
  isSubscriberRoute,
  ...rest
}) => {
  const { authSite, auth } = useStores()

  const { user: userSite } = authSite
  const { user: userAdmin } = auth

  if (_isEmpty(userSite) && !isAdminRoutes) {
    return <Redirect to="/login" noThrow />
  }

  if (_isEmpty(userAdmin) && isAdminRoutes) {
    return <Redirect to="/admin" noThrow />
  }

  if (isSubscriberRoute && userSite.status !== 1 && !userSite.type) {
    return <Redirect to="/minha-conta#inactive=true" noThrow />
  }

  return <Component {...rest} />
}

const IndexPage = (props) => {
  const [isClient, setIsClient] = useState(false)
  const [refreshFeed, setRefreshFeed] = useState(false)
  const { authSite, affiliated, registrationSubscriber } = useStores()

  const { updatePlayerId } = affiliated
  const { updateSubscriberPlayerId } = registrationSubscriber

  const { user: userSite } = authSite
  if (userSite) {
    const type = userSite.type ? "affiliated" : "subscriber"
    const user = { ...userSite, type }
    if (user) {
      initOneSignal(user, updateSubscriberPlayerId, updatePlayerId)
    }
  }

  useEffect(() => {
    setIsClient(true)

    document.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault()
      },
      false
    )

    document.addEventListener("keydown", function (e) {
      if (e.keyCode === 123) {
        e.preventDefault()
      }
    })
  }, [])

  if (!isClient) return null

  const isAdmin = props.location.pathname.includes("admin")

  return (
    <LayoutSite {...props} setRefreshFeed={setRefreshFeed}>
      <Router basepath="/">
        {/** Not authenticated site routes */}
        {/* <SiteLandingPage path="/" /> */}

        <SiteTermos path="/termos" />
        <SitePrivacidade path="/privacidade" />

        <NewSiteLandingPage path="/" />
        <SitePlanos path="/planos" />
        <SiteFAQ path="/faq" />
        <SiteLogin path="login" />
        <SiteBillet path="boleto" />
        <SiteBilletExpired path="boleto/boleto-expirado" />
        <SiteBilletNew path="boleto/novo-boleto" />
        <SiteRegisterSubscriber path="cadastro-assinante" />
        <SiteRegisterAffiliated path="cadastro-criador" />
        {/** Not authenticated admin routes */}
        <Admin path="admin" />
        <AdminChangePassword path="admin/alterar-senha" />
        {/** Authenticated site routes */}
        <PrivateRoute
          path="conta-assinante"
          component={SiteSubscriberAccount}
          props={props}
        />
        <PrivateRoute
          path="conta-criador"
          component={SiteAccountModelPhotographer}
        />
        <PrivateRoute path="ensaios" component={SiteEssay} isSubscriberRoute />
        <PrivateRoute
          path="ensaios/:name"
          component={SiteEssayDetails}
          isSubscriberRoute
        />
        <PrivateRoute
          path="favoritos"
          component={SiteFavorite}
          isSubscriberRoute
        />
        <PrivateRoute
          path="feed"
          component={SiteFeed}
          refreshFeed={refreshFeed}
          setRefreshFeed={setRefreshFeed}
          isSubscriberRoute
        />
        <PrivateRoute
          path="criadores"
          component={CreatorsPage}
          isSubscriberRoute
        />
        <PrivateRoute
          path="minha-conta"
          component={SiteMyAccount}
          props={props}
        />
        <PrivateRoute
          path="minha-conta/alterar-email-publico"
          component={SiteMyAccountChangeEmail}
        />
        <PrivateRoute
          path="minha-conta/esqueci-minha-senha"
          component={SiteMyAccountForgotPassword}
        />
        <PrivateRoute
          path="minha-conta/trocar-senha"
          component={SiteMyAccountChangePassword}
        />
        {/* Ficar atento aqui no perfil*/}
        <SiteProfile path="perfil/:id" component={SiteProfile} />
        <PrivateRoute
          path="p/:id"
          component={SitePostProfile}
          isSubscriberRoute
        />

        <PrivateRoute
          path="perfil/nao-assinante"
          component={SiteProfileNotSubscriber}
        />
        <PrivateRoute path="pagamento-pix" component={PixPage} />

        <PrivateRoute
          path="notificacoes"
          component={NotificationsPage}
          isSubscriberRoute
        />
        {/** Authenticated admin routes */}
        <PrivateRoute
          path="admin/assinantes"
          component={AdminSubscribers}
          isAdminRoutes
        />
        <PrivateRoute
          path="admin/criadores"
          component={AdminCreators}
          isAdminRoutes
        />
        <PrivateRoute
          path="admin/banners"
          component={AdminEssayBanners}
          isAdminRoutes
        />
        <PrivateRoute
          path="admin/banners/cadastrar"
          component={AdminEssayBannerForm}
          isAdminRoutes
        />
        <PrivateRoute
          path="admin/banners/editar/:id"
          component={AdminEssayBannerForm}
          isAdminRoutes
        />
        <PrivateRoute
          path="admin/ensaios"
          component={AdminEssay}
          isAdminRoutes
        />
        <PrivateRoute
          path="admin/ensaios/cadastrar"
          component={AdminEssayRegister}
          isAdminRoutes
        />
        <PrivateRoute
          path="admin/ensaios/editar/:id"
          component={AdminEssayRegister}
          isAdminRoutes
        />
        <PrivateRoute
          path="admin/postagens"
          component={PostAdminPage}
          isAdminRoutes
        />
        <PrivateRoute
          path="admin/usuarios"
          component={AdminUsers}
          isAdminRoutes
        />
        {/** Page not found */}
        <NotFound
          path={
            isAdmin ? "admin/pagina-nao-encontrada" : "pagina-nao-encontrada"
          }
          isAdmin={isAdmin}
          default
        />
      </Router>
    </LayoutSite>
  )
}

export default IndexPage
