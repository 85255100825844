import cx from "classnames"
import { Link } from "gatsby"
import React from "react"

import lightImage from "../../../../images/content/trio-pic.png"
import darkImage from "../../../../images/content/trio-pic-dark.png"
import Image from "../Image"
import ScrollButton from "../ScrollButton"
import ScrollParallax from "../ScrollParallax"
import styles from "./Hero.module.less"
import translate from "@src/i18n"
import { FieldTimeOutlined } from "@ant-design/icons"

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cx("container", styles.container)}>
        <ScrollParallax className={styles.wrap}>
          <div className={cx("stage", styles.stage)}>Conheça o Anonimatta</div>
          <h1 className={cx("h1", styles.title)}>
            Você nunca viu tanto conteúdo no mesmo lugar
          </h1>
          <div className={styles.text}>
            {translate("landingPageHeroSubtitle")}
          </div>
          <div className={styles.btns}>
            <Link
              className={cx("button", styles.button)}
              to="/cadastro-assinante"
            >
              Assine agora
            </Link>
            <Link
              className={cx("button-stroke", styles.button)}
              to="/cadastro-criador"
            >
              Para Criadores
            </Link>
          </div>
        </ScrollParallax>
        <ScrollButton
          onScroll={() =>
            scrollToRef.current.scrollIntoView({ behavior: "smooth" })
          }
          className={styles.scroll}
        />
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <Image
              srcSet={lightImage}
              srcSetDark={darkImage}
              src={lightImage}
              srcDark={darkImage}
              alt="trio-pic"
            />
          </ScrollParallax>
        </div>
      </div>
    </div>
  )
}

export default Hero
