import { PlusOutlined } from "@ant-design/icons"
import { Icon } from "@components/Icons"
import { useStores } from "@hooks/use-stores"
import { PostStatus } from "@modules/Post/store"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import { getWatermarkURL } from "@utils/path"
import { Button, Divider, Form, Input, Upload } from "antd"
import _get from "lodash/get"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { Player } from "video-react"
import { resizeImage } from "../../../../../utils/file"
import { useMedia } from "react-use-media"

import styles from "./style.module.less"

const MAX_CHARS = 800

export const Content = ({
  setFileReader,
  fileReader,
  loading,
  setLoading,
  setErrors,
  setSuccessMessage,
}) => {
  const [loadingFileReader, setLoadingFileReader] = useState(false)
  const [file, setFile] = useState(null)

  const intl = useIntl()

  const [form] = Form.useForm()

  const { authSite, post, upload } = useStores()

  const { user } = authSite

  const { createPost, checkImageUploaded } = post

  const { getSignedUrlS3, uploadToS3 } = upload

  const isMobile = useMedia({ maxWidth: 767 })

  // const urlProfile = getWatermarkURL(user.urlProfile || user.urlPhoto, user)
  const urlProfile = `${process.env.S3_IMAGES}/${user.urlProfile}`

  const getFileReader = (file) => {
    setLoadingFileReader(true)

    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = () => {
        setLoadingFileReader(false)
        resolve(reader.result)
      }
      reader.onerror = (error) => reject(error)
    })
  }

  const poll = async ({ fn, validate, interval, maxAttempts }) => {
    let attempts = 0

    const executePoll = async (resolve, reject) => {
      const result = await fn()
      attempts++

      if (validate(result)) {
        return resolve(result)
      } else if (maxAttempts && attempts === maxAttempts) {
        return reject(new Error("Exceeded max attempts"))
      } else {
        setTimeout(executePoll, interval, resolve, reject)
      }
    }

    return new Promise(executePoll)
  }

  // VALIDA O TAMANHO DO ARQUIVO ~ TAMANHO MAXIMO DE 100MB
  function beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 100
    return isLt2M
  }

  // const filesExtensions = [
  //   "image/jpg",
  //   "image/jpeg",
  //   "image/png",
  //   "video/mp4",
  //   "video/mpg",
  //   "video/mpeg",
  //   "video/avi",
  //   "video/mov",
  //   "video/wmv",
  // ]

  // VALIDA O ARQUIVO E O TAMANHO PARA GERAR O PREVIEW OU MOSTRAR O ERRO
  const handlePreviewMedia = async (media) => {
    if (media) {
      setFile(media.file.originFileObj)

      const fileReader = await getFileReader(media.file.originFileObj)

      // if (filesExtensions.includes(media.file.type)) {
      //   setFileReader(fileReader)
      // } else {
      //   const adaptedErrors = errorsAdapter("Formato de arquivo não suportado.")
      //   setErrors(adaptedErrors)
      //   return
      // }

      form.setFieldsValue({
        media: { name: media.file.name, data: fileReader },
      })

      const checkFileSize = beforeUpload(media.file.originFileObj)

      if (checkFileSize) {
        setFileReader(fileReader)
      } else {
        const adaptedErrors = errorsAdapter(
          "Mídia muito grande. Envie uma versão menor do que 100mb."
        )
        setErrors(adaptedErrors)
      }
    }
  }

  const handleChangeDescription = (e) => {
    const value = e.target.value
    form.setFieldsValue({ description: value })
  }

  const handleRemoveFileReader = () => {
    setFileReader("")
    form.setFieldsValue({ media: null })
  }

  const handleFinish = async ({ description }) => {
    setLoading(true)

    const params = {
      mediaPath: file.type.includes("image") ? "images" : "videos",
      fileName: file.name,
      s3Path: "post",
    }

    const signedUrlRes = await getSignedUrlS3(params)

    if (!signedUrlRes) {
      setLoading(false)
      const adaptedErrors = errorsAdapter("Error get signed url")
      setErrors(adaptedErrors)
      return
    }

    const { url, filePath } = signedUrlRes

    const imagesExtensions = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
    ]

    let resizedImage = null

    if (imagesExtensions.includes(file.type)) {
      resizedImage = await resizeImage(file, 1200)
    }

    const uploadToS3Res = await uploadToS3(url, resizedImage || file)

    if (!uploadToS3Res) {
      setLoading(false)
      const adaptedErrors = errorsAdapter("Error file upload to S3")
      setErrors(adaptedErrors)
      return
    }

    const { id, type } = user

    const media = file.type.includes("image")
      ? { urlImage: filePath }
      : { urlVideo: filePath }

    const payload = {
      affiliatedId: id,
      postText: description,
      type,
      status: PostStatus.ACTIVE,
      ...media,
    }

    const preSignedUrlRes = await createPost(payload)

    const errorMessage = _get(preSignedUrlRes, "message", "")

    if (errorMessage) {
      setLoading(false)
      const adaptedErrors = errorsAdapter(preSignedUrlRes.message)
      setErrors(adaptedErrors)
      return
    }

    if (media.urlImage) {
      const checkImage = async () => {
        const imageUploaded = await checkImageUploaded(media.urlImage)
        return imageUploaded
      }
      const imageReady = (imageUploaded) => !imageUploaded.includes("Error")
      const pollInterval = 500
      await poll({
        fn: checkImage,
        validate: imageReady,
        interval: pollInterval,
      })
        .then(() => {
          setSuccessMessage(
            intl.formatMessage({ id: "newPost.successMessage" })
          )
          setLoading(false)
          return
        })
        .catch((error) => {
          console.log("error ao esperar imagem", error)
        })
    }

    setSuccessMessage(intl.formatMessage({ id: "newPost.successMessage" }))
    setLoading(false)
  }

  const isImage = fileReader.includes("image")

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      initialValues={{
        description: "",
        media: null,
      }}
      className="form__new__post"
    >
      <div className={`NewPostModalContent ${styles.form__content}`}>
        <div>
          <div className="flex flex-row">
            <Form.Item
              name="description"
              style={
                isMobile
                  ? { width: "100%", marginBottom: 0 }
                  : { width: "100%" }
              }
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({ id: "requiredInput" }),
                },
              ]}
            >
              <Input.TextArea
                placeholder={intl.formatMessage({
                  id: "newPost.descriptionPlaceholder",
                })}
                rows={5}
                maxLength={MAX_CHARS}
                className="postage-text"
                onChange={handleChangeDescription}
                showCount={isMobile ? false : true}
              />
            </Form.Item>
          </div>
        </div>
        {fileReader ? (
          <div className={styles.containerImage}>
            <Icon
              name="RemovePhoto"
              className={styles.backgroundRemove}
              onClick={handleRemoveFileReader}
            />

            {isImage ? (
              <div
                className={styles.postageSize}
                style={{
                  backgroundImage: `url(${fileReader})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "208px",
                  width: "173px",
                }}
              />
            ) : (
              <Player
                className={styles.postageSize}
                fluid={false}
                width={200}
                height={208}
                playsInline
                src={fileReader}
              />
            )}
          </div>
        ) : null}
        <div className={styles.footer_container}>
          <Form.Item
            name="media"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "requiredInput" }),
              },
            ]}
          >
            <>
              <div className="flex flex-column items-center">
                <Upload
                  type="file"
                  multiple={false}
                  accept="image/*,video/*"
                  showUploadList={false}
                  onChange={handlePreviewMedia}
                  className={styles.uploadPhoto}
                  customRequest={({ onSuccess }) => {
                    setTimeout(() => {
                      onSuccess("ok")
                    }, 0)
                  }}
                >
                  <Button
                    type="primary"
                    icon={
                      <img
                        className="pointer"
                        src={require("@images/icons/upload.svg")}
                        style={{ marginRight: "6px", marginBottom: "6px" }}
                        width="16px"
                        height="16px"
                        alt="Upload"
                      />
                    }
                    className="button-media"
                    loading={loadingFileReader}
                  >
                    {!fileReader
                      ? intl.formatMessage({ id: "newPost.uploadFile" })
                      : intl.formatMessage({
                          id: "newPost.changeImageOrVideo",
                        })}
                  </Button>
                </Upload>
              </div>
            </>
          </Form.Item>
          <Form.Item>
            <div className="flex justify-center">
              <Button
                type="primary"
                className="button-postage"
                htmlType="submit"
                loading={loading || loadingFileReader}
              >
                {intl.formatMessage({ id: "newPost.buttonSubmit" })}
              </Button>
            </div>
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}
