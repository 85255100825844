import styles from "@components/anonimattaMenu/style.module.less"
import { Button } from "antd"
import cx from "classnames"
import React from "react"
import * as Icon from "react-feather"

import { MenuItemLinkPaths } from "../../"

export const NewPost = ({
  desktop,
  currentPathName,
  setVisibleNewPostModal,
}) => {
  const handleClickNewPost = () => {
    setVisibleNewPostModal(true)
  }

  if (desktop) {
    return (
      <div className="fixed right-1 bottom-2 z-max flex flex-column items-center new-post-desktop">
        <Button
          className={cx("secondaryButton", styles.buttonPost)}
          onClick={handleClickNewPost}
        >
          <Icon.Plus size="20" />
        </Button>
      </div>
    )
  }

  return (
    <Button
      className={cx("flex flex-column items-center", styles.buttonPostMobile)}
      onClick={handleClickNewPost}
    >
      <div
        className={cx("flex", styles.backgroundPost, {
          "bg-anonimatta": currentPathName.includes(MenuItemLinkPaths.POST),
          "bg-anonimatta": !currentPathName.includes(MenuItemLinkPaths.POST),
        })}
      >
        <Icon.Plus size="18" color="#fff" />
      </div>

      <div className="text-menu-mobile">
        <label
          className={cx("label-menu-mobile", {
            "french-rose": currentPathName.includes(MenuItemLinkPaths.POST),
            "default-color": !currentPathName.includes(MenuItemLinkPaths.POST),
          })}
        >
          {/* Criar */}
        </label>
      </div>
    </Button>
  )
}
