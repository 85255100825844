import "./index.less"

import TranslateWrapper from "@components/TranslateWrapper"
import Favorites from "@modules/Favorites"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"

import PageCenter from "../../components/PageCenter"

const FavoritePage = () => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) return null

  return (
    <TranslateWrapper>
      <div className="page">
        <Helmet>
          <title>Favoritos - Anonimatta</title>
        </Helmet>

        <PageCenter>
          <div className="favorite-page-context">
            <Favorites />
          </div>
        </PageCenter>
      </div>
    </TranslateWrapper>
  )
}

export default FavoritePage
