import "./style.module.less"

import TranslateWrapper from "@components/TranslateWrapper"
import { Modal } from "antd"
import cx from "classnames"
import React from "react"

const CustomModal = ({
  visible,
  handleClose,
  children,
  className,
  closable,
  centered,
  destroyOnClose,
  minHeight = "auto",
}) => {
  return (
    <Modal
      style={{ minHeight }}
      visible={visible}
      centered={centered}
      className={cx("modalPost", className)}
      footer={false}
      onCancel={handleClose}
      destroyOnClose={destroyOnClose}
      closable={closable}
    >
      <TranslateWrapper>{children}</TranslateWrapper>
    </Modal>
  )
}

export default CustomModal
