import styles from "@components/Gallery/style.module.less"
import { Icon } from "@components/Icons"
import { useStores } from "@hooks/use-stores"
import Banner from "@modules/Favorites/Banner"
import Feed from "@modules/Favorites/Feed"
import cx from "classnames"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"

const Favorites = () => {
  const [posts, setPosts] = useState(null)
  const [photosEssay, setPhotosEssay] = useState(null)
  const [videosEssay, setVideosEssay] = useState(null)
  const [profilesFollowing, setProfilesFollowing] = useState(null)
  const [totalItems, setTotalItems] = useState(0)
  const [loadingPosts, setLoadingPosts] = useState(true)
  const [loadingEssayMedia, setLoadingEssayMedia] = useState(true)
  const [loadingProfilesFollowing, setLoadingProfilesFollowing] = useState(true)

  const { favorites, feed, authSite } = useStores()
  const { user } = authSite

  const {
    findAllPostsLikedByUser,
    findAllEssayMediasLikedByUser,
    findAllFollowing,
  } = favorites
  const { postNewFollow, deletedFollowers } = feed

  const handleGetFavorites = async () => {
    const { data: dataFeed, total: totalFeed } = await findAllPostsLikedByUser(
      user.id
    )

    setPosts(dataFeed)
    setLoadingPosts(false)

    const { data: dataEssay, total: totalEssay } =
      await findAllEssayMediasLikedByUser()

    const photosEssayData = [
      ...dataEssay
        .filter((itemEssay) => itemEssay.type === 1)
        .map((itemEssay) => ({
          ...itemEssay,
          id: itemEssay.photoVideoId,
          urlPhoto: itemEssay.urlPhotoVideo,
          totalLikes: itemEssay.likes,
          liked: true,
        })),
    ].sort((a, b) => new Date(b.dateLike) - new Date(a.dateLike))

    const videosEssayData = [
      ...dataEssay
        .filter((itemEssay) => itemEssay.type === 2)
        .map((itemEssay) => ({
          ...itemEssay,
          id: itemEssay.photoVideoId,
          urlVideo: itemEssay.urlPhotoVideo,
          totalLikes: itemEssay.likes,
          liked: true,
        })),
    ].sort((a, b) => new Date(b.dateLike) - new Date(a.dateLike))

    setPhotosEssay(photosEssayData)
    setVideosEssay(videosEssayData)
    setLoadingEssayMedia(false)

    const { following, total: totalFollowing } = await findAllFollowing(user.id)

    setProfilesFollowing(following)
    setLoadingProfilesFollowing(false)
    setTotalItems(totalFeed + totalEssay + totalFollowing)
  }

  useEffect(() => {
    handleGetFavorites()
  }, [])

  const handleFollowers = async (id) => {
    const { data } = await postNewFollow(id)

    if (data.success === true) {
      return true
    }
    return false
  }

  const handleUnfollowers = async (id) => {
    const { data } = await deletedFollowers(id)

    if (data.success === true) {
      return true
    }
    return false
  }

  const handleModelProfile = (event, id) => {
    navigate(`/perfil/${id}`)
    event.stopPropagation()
  }

  return (
    <div className="flex flex-column">
      <Icon
        name="HeartFilled"
        className={cx("f24 french-rose w0 h0 absolute", styles.hiddenIcon)}
      />
      <Banner total={totalItems} />
      <Feed
        posts={posts}
        photosEssay={photosEssay}
        videosEssay={videosEssay}
        profilesFollowing={profilesFollowing}
        loadingPosts={loadingPosts}
        loadingEssayMedia={loadingEssayMedia}
        loadingProfilesFollowing={loadingProfilesFollowing}
        handleFollowers={handleFollowers}
        handleUnfollowers={handleUnfollowers}
        handleModelProfile={handleModelProfile}
        handleLikeUnlike={handleGetFavorites}
      />
    </div>
  )
}

export default Favorites
