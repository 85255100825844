import * as Icon from "react-feather"
import translate from "@src/i18n"
import { Button } from "antd"
import cx from "classnames"
import _map from "lodash/map"
import React from "react"

import styles from "./style.module.less"

const CustomFilterbar = ({ handleSearch }) => {
  return (
    <>
      <Button
        className={cx(
          "mr12 justify-center items-center custom-search-icon",
          styles.searchButton
        )}
        onClick={handleSearch}
      >
        <Icon.Search color="#777" size="20" /> <span>Pesquisar</span>
      </Button>
    </>
  )
}

export default CustomFilterbar
